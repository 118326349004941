/* globals window */

import { isEmpty } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { useUserStore } from '../store/userStore';
import { useLocation } from './useLocation';

declare global {
  interface Window {
    utag: any;
    utag_data: any;
  }
}

interface TealiumContextProps {
  userData: any;
  trackEvent: (
    eventCategory: string,
    eventAction: string,
    eventLabel: string
  ) => void;
  trackPageView: (location: any) => void;
}

interface CarDataProps {
  year: string;
  make: string;
  model: string;
}

export const TealiumContext = React.createContext<TealiumContextProps | null>(
  null
);

/*
  For information on SPAs with tealium:
  https://docs.tealium.com/platforms/javascript/single-page-applications/

  I've decided to use a Context here so that we load the UDO data once and
  have access to it on each trackEvent call. Note that the currentCarUuid and
  cars object are supplied from the Redux Store.
*/
export function TealiumProvider({ currentCarUuid, cars, children }) {
  const [carData, setCarData] = React.useState<
    CarDataProps | Record<string, never>
  >({});
  const location = useLocation();

  // Get that currently selected car and make available to tealium
  // TODO: When we get rid of redux remove this, KISS
  React.useEffect(() => {
    if (isEmpty(cars) || cars.isFetching) {
      setCarData({});
      return;
    }
    const currentCar = cars.byUuid[currentCarUuid];
    if (isEmpty(currentCar)) {
      setCarData({});
    } else {
      setCarData({
        model: currentCar.model,
        make: currentCar?.make.name,
        year: currentCar.year,
      });
    }
  }, [currentCarUuid, cars]);

  React.useEffect(() => {
    trackPageView(location);
  }, [location]);

  // should this be set with useEffect?
  const currentLocation = location;

  const userData = useUserStore((state) => state.currentUser);

  // These need to be supressed per Google's guidelines.
  // See: https://docs.tealium.com/platforms/javascript/single-page-applications/
  // customer_id: userData.username,
  // login_name: userData.username,
  const utag_data = {
    customer_id: userData.id,
    aap_store_number: userData.account_number,
    account_name: userData.shop_name,
    account_number: userData.account_number,
    page_name: currentLocation.pathname,
    auto_make: carData.make || '',
    auto_model: carData.model || '',
    auto_year: carData.year || '',
  };

  const trackEvent = (
    eventCategory: string,
    eventAction: string,
    eventLabel: string
  ) => {
    if (window.utag) {
      const trackingData = {
        event_category: eventCategory,
        event_action: eventAction,
        event_label: eventLabel,
        ...utag_data,
      };
      console.log('trackEvent: ', trackingData);

      window.utag.link(trackingData);
    } else {
      console.warn("trackEvent: utag isn't defined, can't track event");
    }
  };

  const trackPageView = (location) => {
    if (window.utag) {
      window.utag.view(utag_data);
    } else {
      console.warn("trackPageView: utag isn't defined, can't track pageView");
    }
  };

  return (
    <TealiumContext.Provider
      value={{
        userData,
        trackEvent,
        trackPageView,
      }}
    >
      {children}
    </TealiumContext.Provider>
  );
}

const mapStateToProps = (state) => {
  return {
    cars: state.cars,
    currentCarUuid: state.currentCarUuid,
  };
};

export default connect(mapStateToProps)(TealiumProvider);

export const useTracking = () => {
  const tealium = React.useContext(TealiumContext);
  return tealium;
};

/**
 * Use when tracking is required outside of the logged in state
 * @returns
 */
export const useLoggedOutTracking = () => {
  const trackEvent = (
    eventCategory: string,
    eventAction: string,
    eventLabel: string
  ) => {
    if (window.utag) {
      const trackingData = {
        event_category: eventCategory,
        event_action: eventAction,
        event_label: eventLabel,
      };
      window.utag.link(trackingData);
    } else {
      console.warn("trackEvent: utag isn't defined, can't track event");
    }
  };

  return { trackEvent };
};
