// TODO: Look further at this:
// https://github.com/badgateway/react-ketting/blob/main/src/components/RequireLogin.tsx
import { create, StateCreator, StoreApi } from 'zustand';
import { devtools, combine } from 'zustand/middleware';

interface IUser {
  id: string | null;
  logged_in: boolean;
  username: string;
  email: string;
  display_login: string;
  shop_name: string;
  internal_corporate: string;
  can_print: boolean;
  can_bookmark: boolean;
  account_number: string;
  feature_set: string;
  trial: boolean;
  subscriptions: any;
}

export interface ILoginSlice {
  isLoggedIn: boolean;
  setCurrentUser: (userData: IUser) => void;
  currentUser: IUser | null;
}

interface IStore extends ILoginSlice {}

export const EMPTY_USER = {
  id: null,
  logged_in: false,
  username: '',
  email: '',
  display_login: '',
  shop_name: '',
  internal_corporate: '',
  can_print: false,
  can_bookmark: false,
  account_number: null,
  feature_set: '',
  trial: false,
  subscriptions: [],
} as IUser;

// const SIGNED_OUT_USER = {
//   id: null,
//   username: 'signedout',
//   email: '',
//   shop_name: '',
//   shop_type: '',
//   account_number: '',
//   can_print: false,
//   can_bookmark: false,
//   feature_set: '',
//   trial: true,
//   subscriptions: [],
// };

// export const useUserStore = create<IStore, [['zustand/devtools', IStore]]>(
//   devtools(
//     (set, _get) => ({
//       isLoggedIn: false,
//       currentUser: EMPTY_USER,
//       setCurrentUser: (userData: IUser) => {
//         set({ isLoggedIn: userData.logged_in, currentUser: userData });
//       },
//     }),
//     { name: 'userStore', enabled: true }
//   )
// );

export const useUserStore = create<IStore, [['zustand/devtools', IStore]]>(
  (set, _get) => ({
    isLoggedIn: false,
    currentUser: EMPTY_USER,
    setCurrentUser: (userData: IUser) => {
      set({ isLoggedIn: userData.logged_in, currentUser: userData });
    },
  })
);
